import React, { useState } from 'react';
import { Collapse, Nav, Navbar, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Location } from '@reach/router';
import { navigate } from 'gatsby';

const MenuBar = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);
  const scroll = (selector, pathname) => {
    if(pathname !== '/') {
      navigate(`/${selector}`);
      return;
    }

    document.querySelector(selector).scrollIntoView({
      behavior: 'smooth'
    });
  }
//href="#about"
  return (
    <Location>
      {({location: {pathname}}) => 
        <div className="menu-bar px-0">
          <Navbar expand="md" className="justify-content-end">
            <NavbarToggler onClick={toggle} />
            <Collapse className="flex-row-reverse menu-bar__content" isOpen={isOpen} navbar>
              <Nav className="" navbar>
                <NavItem>
                  <NavLink onClick={scroll.bind(null, '#about', pathname)} >o nas</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink onClick={scroll.bind(null, "#offer", pathname)}>oferta</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink onClick={scroll.bind(null, "#courses", pathname)}>aktualności</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink onClick={scroll.bind(null, "#contact", pathname)}>kontakt</NavLink>
                </NavItem>
              </Nav>
            </Collapse>
          </Navbar>
        </div>
      }
    </Location>
  );
}

export default MenuBar;
