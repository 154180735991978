import { graphql, Link, useStaticQuery } from "gatsby"
import Helmet from 'react-helmet';
import 'prismjs/themes/prism-okaidia.css'
import React from "react"
import MenuBar from '../components/menu-bar'
import logo from "../../static/assets/images/logo.png"


export default ({ children }) => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `
  )
  return (
    <div className="site-wrapper">
      <Helmet>
        
        <meta name="description" content={data.site.siteMetadata.description} />
        <meta name="keywords" content={data.site.siteMetadata.keywords}/>
        <meta name="robots" content="index,follow"/>
        <meta name="revisit-after" content="7 days"/>
        <meta name="Content-Language" content="pl"/>

        <meta property="og:title" content={data.site.siteMetadata.title} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://osk-dziwisz.pl/" />
        <meta property="og:description" content={data.site.siteMetadata.description} />
        <meta property="og:image" content={logo} />

        <link rel="preload" href="https://fonts.googleapis.com/css2?family=Alata&family=Work+Sans&display=swap"/> 
      </Helmet>
      <header className="site-header">
        <div className="site-title">
          <Link to="/">
            <img src={logo} className="logo img-fluid" alt="logo" />
            DZIWISZ
          </Link>
        </div>
        {/* <Navigation /> */}
        <MenuBar/>
      </header>
      <div id="fb-root"></div>

      {children}
      <footer className="site-footer">
        <p>&copy; 2020 Osk-Dziwisz &bull; Crafted with <span role="img" aria-label="love">❤️</span> by <a>Mateusz Sieńczuk</a></p>
      </footer>
      <script type="text/javascript" src="https://identity.netlify.com/v1/netlify-identity-widget.js"></script>
    </div>
  )
}